import { Controller } from "@hotwired/stimulus";
import collapsibleContainer from "@app/components/common/collapsibleContainer";

export default class extends Controller {
  static targets = [
    "form",
    "hiddenInput",
    "formContainer",
    "thankYouMessage",
    "carousel",
    "fileInputField",
    "deleteImageButton",
  ];

  static collapsibleInitialized = false;

  connect() {
    if (!this.constructor.collapsibleInitialized) {
      collapsibleContainer(false, false);
      this.constructor.collapsibleInitialized = true;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const openModal = urlParams.get("open_memory_modal");

    if (openModal === "true") {
      this.initializeModal();
    }

    if (this.hasCarouselTarget) {
      this.toggleFileInputVisibility();
    }

    this.handleCloseButtons();
    if (this.hasFormTarget) {
      this.photoExists = this.formTarget.dataset.photoExists == "true";
      this.checkFormValidity();
    }
  }

  async submit(event) {
    event.preventDefault();

    this.validateFields();
    if (!this.formTarget.checkValidity()) {
      this.formTarget.reportValidity();
      return;
    }

    this.toggleButtonLoading();

    const formData = new FormData(this.formTarget);
    if (this.formTarget.dataset.actionType === "edit") {
      formData.append("_method", "PATCH");
    }
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    const response = await fetch(this.formTarget.action, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body: formData,
    });

    if (response.ok) {
      if (this.hasFormContainerTarget) {
        this.formContainerTarget.classList.add("hidden");
        this.thankYouMessageTarget.classList.remove("hidden");
      } else {
        location.reload();
      }
    }
  }

  initializeModal() {
    const interval = 2000;
    const maxAttempts = 20;

    let attempts = 0;

    const checkCarouselInstance = () => {
      if (!window.$hsCarouselCollection) {
        window.$hsCarouselCollection = [];
      }

      const modalInstance = window.HSOverlay.getInstance("#modal-custom_memory");

      if (modalInstance) {
        window.HSOverlay.open("#modal-custom_memory");

        const url = new URL(window.location.href);
        url.searchParams.delete("open_memory_modal");
        window.history.replaceState({}, document.title, url);
      } else if (attempts < maxAttempts) {
        attempts++;
        setTimeout(checkCarouselInstance, interval);
      } else {
        console.error("No carousel after 20 attempts.");
      }
    };

    checkCarouselInstance();
  }

  async submitHide(event) {
    event.preventDefault();

    this.hiddenInputTarget.value = event.target.textContent.startsWith("Hide") ? "true" : "false";
    const formData = new FormData(this.formTarget);
    formData.append("_method", "PATCH");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    const response = await fetch(this.formTarget.action, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body: formData,
    });

    if (response.ok) {
      this.element.classList.toggle("message-hidden");
    }
  }

  reloadPartial(event) {
    event.preventDefault();

    this.disableButtonsInPartial(document.querySelector("#partial-navigation-content"));

    const url = new URL(window.location.href);
    const partialName = url.searchParams.get("partial");

    const customEvent = new CustomEvent("partial_navigation:loadPartial", {
      detail: { partialName: partialName },
    });
    document.dispatchEvent(customEvent);
  }

  disableButtonsInPartial(partial) {
    const buttons = partial.querySelectorAll("button");
    buttons.forEach((button) => {
      button.disabled = true;
    });
  }

  toggleButtonLoading() {
    const submitButton = this.formTarget.querySelector("button[type='submit']");
    const spinnerElement = submitButton.querySelector("[name='spinner']");

    if (spinnerElement) {
      const buttonTextElement = submitButton.querySelector("[name='button-text']");
      const icon = submitButton.querySelector("svg");

      icon?.classList.toggle("hidden");
      spinnerElement.classList.toggle("hidden");
      buttonTextElement.textContent = "Saving";
    }
  }

  validateFields() {
    setTimeout(() => {
      const inputs = this.formTarget.querySelectorAll("input, select");
      let isAttachmentFilled = false;
      let isContentFilled = false;

      inputs.forEach((input) => {
        if (input.validity.patternMismatch) {
          input.setCustomValidity(
            "Please use normal characters (letters, apostrophe's and hyphens only)."
          );
        } else if (input.type === "file") {
          if (input.files.length > 0) {
            isAttachmentFilled = true;
          }
        } else if (input.value.length > 100) {
          input.setCustomValidity("This field cannot exceed 100 characters.");
        }
      });

      const contentInput = this.formTarget.querySelector("textarea");

      if (contentInput?.value.trim() !== "") {
        isContentFilled = true;
      }

      if (!this.photoExists && !isAttachmentFilled && !isContentFilled) {
        if (contentInput) {
          contentInput.setCustomValidity(
            "Please upload a photo or write a message to submit a memory."
          );
        }
      } else {
        if (contentInput) {
          contentInput.setCustomValidity("");
          contentInput.removeAttribute("required");
        }
      }

      this.toggleSubmitButton();
    }, 100);
  }

  addAnotherMemory() {
    this.formContainerTarget.classList.remove("hidden");
    this.thankYouMessageTarget.classList.add("hidden");

    const inputs = this.formTarget.querySelectorAll("textarea, input, select");
    inputs.forEach((input) => {
      if (
        ![
          "tribute_memory[first_name]",
          "tribute_memory[last_name]",
          "tribute_memory[email]",
          "tribute_memory[uploaded_by_nok]",
        ].includes(input.name)
      ) {
        input.value = "";
      }
    });

    this.toggleSubmitButton();
    this.toggleButtonLoading();
    this.resetFiles();
  }

  handleCloseButtons() {
    const closeButtons = document.querySelectorAll('button[id$="close-button"]');

    closeButtons.forEach((button) => {
      if (!button.dataset.listenerAdded) {
        button.addEventListener(
          "click",
          function (event) {
            this.reloadPartial(event);
          }.bind(this)
        );
        button.dataset.listenerAdded = "true";
      }
    });
  }

  toggleFileInput() {
    const fileInput = this.fileInputFieldTarget.querySelector("input[type='file']");
    this.fileInputFieldTarget.classList.remove("hidden");
    fileInput.setAttribute("name", "tribute_memory[append_memory_attachments][]");
    this.carouselTarget.classList.add("hidden");
  }

  toggleFileInputVisibility() {
    const fileInput = this.fileInputFieldTarget.querySelector("input[type='file']");
    const hiddenInput = this.fileInputFieldTarget.querySelector("input[type='hidden']");

    if (this.fileInputFieldTarget.classList.contains("hidden")) {
      fileInput.removeAttribute("name");
      if (hiddenInput) {
        hiddenInput.remove();
      }
    }
  }

  async removeFile(event) {
    event.preventDefault();

    const carouselBody = this.carouselTarget.querySelector(".hs-carousel-body");
    const indexToRemove = carouselBody.dataset.index;
    const carouselImages = Array.from(carouselBody.querySelectorAll("img"));

    if (carouselImages.length === 1) {
      this.photoExists = false;
      this.toggleFileInput();
      this.validateFields();
    }

    const imageToRemoveId = carouselImages[indexToRemove].id;

    let formData = new FormData();
    formData.append("_method", "PATCH");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    const response = await fetch(this.deleteImageButtonTarget.dataset.url + "/" + imageToRemoveId, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body: formData,
    });

    if (response.ok) {
      const customEvent = new CustomEvent("carousel:ImageRemoved", {
        detail: { id: imageToRemoveId },
      });

      this.carouselTarget.querySelector("#carousel").dispatchEvent(customEvent);
    }
  }

  checkFormValidity() {
    const inputs = this.formTarget.querySelectorAll("input, textarea, select");
    inputs.forEach((input) => {
      input.addEventListener("input", this.validateFields.bind(this));
    });

    this.validateFields();
  }

  toggleSubmitButton() {
    const submitButton = this.formTarget.querySelector("button[type='submit']");
    if (this.formTarget.checkValidity()) {
      submitButton.removeAttribute("disabled");
    } else {
      submitButton.setAttribute("disabled", "true");
    }
  }

  resetFiles() {
    const fileUploadController = this.application.getControllerForElementAndIdentifier(
      this.element.querySelector('[data-controller="file_upload_input"]'),
      "file_upload_input"
    );

    if (fileUploadController) {
      fileUploadController.resetFiles();
    }
  }
}
